import { Button, Link } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import HamburgerMenu from '../hamburger-menu';
import LogoImage from '../logo-image';

const NavBar = () => {
    // DATA INITIALIZATION
    const navListItem = ['Learn', 'Build', 'Explore', 'Join', 'Help'];
    const navigate = useNavigate();

    // STATES
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [show, setShow] = useState(false);
    const [lastScrollY, setLastScrollY] = useState(0);

    // HANDLERS
    const toggleMenu = () => setIsMenuOpen(!isMenuOpen);
    const controlNavbar = () => {
        if (typeof window !== 'undefined') {
            if (window.scrollY < 30) {
                setShow(false);
            } else if (window.scrollY > lastScrollY) {
                // if scroll down hide the navbar
                setShow(false);
            } else {
                // if scroll up show the navbar
                setShow(true);
            }

            // remember current page location to use in the next move
            setLastScrollY(window.scrollY);
        }
    };

    // SIDE EFFECTS
    useEffect(() => {
        if (isMenuOpen) {
            document.body.style.overflowY = 'hidden';
        }
        return () => {
            document.body.style.overflowY = 'scroll';
        };
    }, [isMenuOpen]);
    useEffect(() => {
        if (typeof window !== 'undefined') {
            window.addEventListener('scroll', controlNavbar);

            // cleanup function
            return () => {
                window.removeEventListener('scroll', controlNavbar);
            };
        }
    }, [lastScrollY]);

    return (
        <header
            className={`w-full bg-black flex items-center justify-center h-[12dvh] bg-transparent bg border-none mt-[5dvh] mobile:mt-0 mobile:left-0 mobile:translate-x-0 mobile:h-fit mobile:flex-col relative mobile:fixed mobile:top-0 mobile:z-[1000] [transition:transform_.7s_ease-in-out] ${
                show && 'mobile:translate-y-[-20dvh]'
            }`}
        >
            <nav className='w-full  rounded-xl py-8 flex justify-between gap-10 items-center h-full mobile:w-full mobile:px-5 mobile:p-0 mobile:my-auto mobile:rounded-none mobile:h-[10dvh] bg-black'>
                {/* logo */}
                <Link className='hover:opacity-80' href='/'>
                    <LogoImage
                        url='/images/main-logo.svg'
                        width='mobile:h-8 pc:w-28'
                    />
                </Link>

                {/* hamburger menu */}
                <HamburgerMenu isMenuOpen={isMenuOpen} onClick={toggleMenu} />

                {/* nav items */}
                <ul className='bg-white text-black px-20 rounded-[100px] py-3 flex items-center gap-8 text-lg font-semibold mobile:hidden'>
                    {navListItem.map((navItem, index) => (
                        <a key={index} className='hover:opacity-60' href='#'>
                            <li className='font-normal text-xl'>{navItem}</li>
                        </a>
                    ))}
                </ul>

                {/* login button */}
                <div className='flex items-center gap-8 mobile:hidden' />
            </nav>

            {/* mobile menu */}
            <ul
                className={`text-white flex flex-col bg-black p-4 py-6 text-center gap-2 text-lg font-semibold [transition:transform_.7s_ease-in-out] w-full fixed h-[90dvh] left-0 top-[10.1dvh] z-[2000] ${
                    isMenuOpen ? 'translate-x-0' : 'translate-x-[100dvw]'
                } pc:hidden`}
            >
                {navListItem.map((navItem, index) => (
                    <a
                        key={index}
                        onClick={toggleMenu}
                        className='py-2 rounded [outline:none]'
                        href='#'
                    >
                        <li className='w-fit border-b-4 mx-auto hover:border-[#5846FB]'>
                            {navItem}
                        </li>
                    </a>
                ))}

                {/* join waitlist */}
                <div className='flex w-full justify-center'>
                    <Button
                        onClick={() => {
                            toggleMenu();
                            navigate('/waitlist');
                        }}
                        _hover={{ opacity: '0.7' }}
                        _active={{ opacity: '0.5' }}
                        bg='#5846FB'
                        h='54px'
                        color='white'
                        rounded='100px'
                        w='100%'
                    >
                        Join Waitlist
                    </Button>
                </div>
            </ul>
        </header>
    );
};

export default NavBar;
