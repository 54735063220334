import {
    Button,
    Flex,
    Link,
    List,
    ListItem,
    Text,
    VStack,
} from '@chakra-ui/react';
import React from 'react';
import LogoImage from '../shared/logo-image';

const Footer = () => {
    return (
        <Flex
            roundedTop={{ base: '14px', lg: '42px' }}
            bg='black'
            id='footer'
            alignItems='center'
            flexDir='column'
            pb={{ base: '6dvh', lg: '10dvh' }}
            px={{ base: '6dvw', lg: '7dvw', xl: '8dvw' }}
            gap={{ base: '36px', lg: '48px' }}
            pt={{ base: '6dvh', lg: '15dvh' }}
        >
            {/* input section */}
            <VStack mt='28px' gap='32px' w='100%' spacing={3}>
                <Text
                    fontSize={{ base: '28px', lg: '52px', xl: '82px' }}
                    color='white'
                    fontWeight={600}
                >
                    Join Our Waitlist !
                </Text>
                <Button
                    as={Link}
                    href='/waitlist'
                    _hover={{ opacity: '0.7', textDecor: 'none' }}
                    _active={{ opacity: '0.5' }}
                    bg='#5846FB'
                    h='54px'
                    color='white'
                    rounded='100px'
                    w={{ base: '55%', lg: '20%' }}
                >
                    Join Waitlist
                </Button>
            </VStack>

            {/* logo and footer links */}
            <Flex
                mt={{ base: '32px', lg: '68px' }}
                w='100%'
                gap='20px'
                flexDir={{ base: 'column', lg: 'row' }}
                justifyContent={{ base: 'flex-start', lg: 'space-between' }}
                alignItems='center'
            >
                <Link href=''>
                    <LogoImage
                        url='/images/main-logo.svg'
                        width='mobile:h-8 pc:w-28'
                    />
                </Link>

                {/* links */}
                <List
                    justifyContent={{ base: 'center', lg: 'flex-start' }}
                    gap='6px'
                    flexWrap='wrap'
                    display='flex'
                    alignItems='center'
                >
                    <ListItem
                        as={Link}
                        fontSize={{ base: '14px', lg: '18px' }}
                        textColor='white'
                    >
                        {'Terms & conditions |'}
                    </ListItem>
                    <ListItem
                        as={Link}
                        href='/blog'
                        fontSize={{ base: '14px', lg: '18px' }}
                        textColor='white'
                    >
                        {'Blog |'}
                    </ListItem>
                    <ListItem
                        as={Link}
                        fontSize={{ base: '14px', lg: '18px' }}
                        textColor='white'
                    >
                        {'Privary Policy |'}
                    </ListItem>
                    <ListItem
                        as={Link}
                        fontSize={{ base: '14px', lg: '18px' }}
                        textColor='white'
                    >
                        {'Cookies Policy'}
                    </ListItem>
                </List>
            </Flex>
        </Flex>
    );
};

export default Footer;
