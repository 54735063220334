import {
    Box,
    Button,
    Divider,
    Flex,
    Grid,
    HStack,
    Icon,
    Input,
    InputGroup,
    InputRightElement,
    Link,
    List,
    ListItem,
    Text,
    VStack,
} from '@chakra-ui/react';
import React from 'react';
import { MdOutlineMail } from 'react-icons/md';

const BlogFooter = () => {
    return (
        <Box
            bg='rgba(0,0,0,.125)'
            w='100%'
            px={{ base: '20px', lg: '52px' }}
            py='48px'
            display='flex'
            flexDir='column'
            gap='24px'
        >
            {/* grid container */}
            <Grid
                templateColumns={{ base: '1fr', lg: 'repeat(3, 1fr)' }}
                gap={{ base: '2rem', lg: '7rem' }}
            >
                {/* about the store */}
                <Flex flexDirection='column' gap='24px'>
                    <VStack spacing='20px' alignItems='flex-start'>
                        <Text
                            textTransform='capitalize'
                            as='h3'
                            fontWeight={500}
                            fontSize={{ base: '20px', lg: '24px' }}
                            color='black'
                        >
                            About
                        </Text>
                        <Text
                            fontSize={{ base: '14px', lg: '20px' }}
                            fontWeight={400}
                            color='black'
                        >
                            Lorem ipsum dolor sit amet, consectetur adipisicing
                            elit. Inventore perspiciatis totam reiciendis
                            incidunt nemo placeat nulla porro. Aperiam quis sed,
                            hic rem, quo labore voluptatum qui, amet ipsum
                            dolorem distinctio?
                        </Text>
                    </VStack>
                    <VStack spacing='8px' alignItems='flex-start'>
                        <HStack alignItems='center' gap='6px'>
                            <Text
                                fontSize='16px'
                                fontWeight={600}
                                color='black'
                            >
                                Email:
                            </Text>
                            <Text
                                fontSize='16px'
                                fontWeight={400}
                                color='black'
                            >
                                victorpeter232@gmail.com
                            </Text>
                        </HStack>
                        <HStack alignItems='center' gap='6px'>
                            <Text
                                fontSize='16px'
                                fontWeight={600}
                                color='black'
                            >
                                Phone:
                            </Text>
                            <Text
                                fontSize='16px'
                                fontWeight={400}
                                color='black'
                            >
                                880 123 456 789
                            </Text>
                        </HStack>
                    </VStack>
                </Flex>

                {/* quick link and categories */}
                <HStack alignItems='flex-start' gap='4.5rem'>
                    {/* quick link */}
                    <Flex
                        display={{ base: 'none', xl: 'flex' }}
                        flexDir='column'
                        gap='20px'
                    >
                        <Text
                            textTransform='capitalize'
                            as='h3'
                            fontWeight={500}
                            fontSize='24px'
                            color='black'
                        >
                            Quick Link
                        </Text>

                        {/* list */}
                        <List
                            flexDir='column'
                            display='flex'
                            w='fit-content'
                            gap='8px'
                            px='0px'
                        >
                            {[
                                'Home',
                                'About',
                                'Blog',
                                'Archived',
                                'Author',
                                'Contact',
                            ].map((item, index) => (
                                <ListItem
                                    key={index}
                                    color='black'
                                    w='100%'
                                    fontSize='20px'
                                    as={Link}
                                    _hover={{ textDecor: 'none' }}
                                    href='#'
                                >
                                    {item}
                                </ListItem>
                            ))}
                        </List>
                    </Flex>

                    {/* categories */}
                    <Flex flexDir='column' gap='20px'>
                        <Text
                            textTransform='capitalize'
                            as='h3'
                            fontWeight={500}
                            fontSize='24px'
                            color='black'
                        >
                            Categories
                        </Text>

                        {/* list */}
                        <List
                            flexDir='column'
                            display='flex'
                            w='fit-content'
                            gap='8px'
                            px='0px'
                        >
                            {[
                                'Lifestyle',
                                'Technology',
                                'Travel',
                                'Business',
                                'Economy',
                                'Sports',
                            ].map((item, index) => (
                                <ListItem
                                    key={index}
                                    color='black'
                                    w='100%'
                                    fontSize='20px'
                                    as={Link}
                                    _hover={{ textDecor: 'none' }}
                                    href='#'
                                >
                                    {item}
                                </ListItem>
                            ))}
                        </List>
                    </Flex>
                </HStack>

                {/* newsletter*/}
                <Flex flexDir='column' gap='14px'>
                    <Flex flexDir='column' gap='16px'>
                        <Text
                            textAlign='center'
                            fontWeight={600}
                            fontSize={{ base: '16px', lg: '24px' }}
                        >
                            Weekly Newsletter
                        </Text>
                        <Text
                            textAlign='center'
                            fontWeight={400}
                            fontSize={{ base: '14px', lg: '18px' }}
                        >
                            Get blog articles and offers via email
                        </Text>
                        <InputGroup>
                            <Input
                                rounded='10px'
                                h='56px'
                                borderWidth='1px'
                                borderColor='gray.400'
                                placeholder='Your Email'
                            />
                            <InputRightElement h='100%'>
                                <Icon
                                    boxSize='30px'
                                    as={MdOutlineMail}
                                    color='gray.400'
                                />
                            </InputRightElement>
                        </InputGroup>
                        <Button
                            _hover={{ opacity: 0.7 }}
                            h='56px'
                            rounded='10px'
                            color='white'
                            bg='#4B6BFB'
                        >
                            Subscribe
                        </Button>
                    </Flex>
                </Flex>
            </Grid>

            <Divider my='24px' borderColor='gray.600' />

            {/* copyright */}
            <Flex
                w='100%'
                flexDir={{ base: 'column', lg: 'row' }}
                alignItems={{ base: 'flex-start', lg: 'flex-end' }}
                justifyContent='space-between'
                textAlign='center'
                gap={{ base: '20px', lg: '4px' }}
            >
                <Flex
                    gap='12px'
                    w={{ base: '100%', lg: 'auto' }}
                    justifyContent={{ base: 'center', lg: 'flex-start' }}
                    alignItems='center'
                >
                    <Text
                        display={{ base: 'none', lg: 'block' }}
                        fontSize={{ base: '16px', lg: '24px' }}
                        fontWeight={700}
                        color='black'
                    >
                        Sunga
                    </Text>
                    <Text
                        fontSize={{ base: '14px', lg: '20px' }}
                        fontWeight={400}
                        color='black'
                    >
                        &copy; {new Date().getFullYear()}. All Rights Reserved.
                    </Text>
                </Flex>
                <Flex
                    w={{ base: '100%', lg: 'auto' }}
                    alignItems='center'
                    gap={{ base: '16px', lg: '20px' }}
                >
                    {['Terms of Use', 'Privacy Policy', 'Cookie Policy'].map(
                        (item, index) => (
                            <Text
                                mx={{ base: 'auto', lg: '0px' }}
                                key={index}
                                fontSize={{ base: '14px', lg: '20px' }}
                                fontWeight={400}
                                color='black'
                            >
                                {item}
                            </Text>
                        )
                    )}
                </Flex>
            </Flex>
        </Box>
    );
};

export default BlogFooter;
