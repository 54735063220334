import React from 'react';

const WaitlistWrapperLayout = ({ children }) => {
    return (
        <main className='h-screen w-screen flex'>
            {/* arcs */}
            <img
                src='/images/blue-arcs.png'
                alt='blue arcs'
                className='fixed -top-10 opacity-20 right-0 -z-10 w-72 scale-150 -rotate-45 mobile:hidden'
            />
            {/* arcs */}
            <img
                src='/images/blue-arcs.png'
                alt='blue arcs'
                className='fixed -bottom-[300px] opacity-20 left-0 -z-10 w-72 -rotate-45 -scale-150 mobile:hidden'
            />

            {/* main section */}
            <section className='no-scrollbar min-h-full w-[70%] mobile:w-[90%] relative py-[12dvh] mobile:py-[8dvh] flex flex-col mx-auto items-center overflow-y-auto'>
                {children}
            </section>
        </main>
    );
};

export default WaitlistWrapperLayout;
