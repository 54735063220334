import { Flex, Grid, GridItem, Text, VStack } from '@chakra-ui/react';
import React from 'react';
import { AiFillThunderbolt } from 'react-icons/ai';
import { CgArrowsExchange } from 'react-icons/cg';
import { FaDollarSign } from 'react-icons/fa';

const WhySunga = () => {
    // DATA INITIALIZATION
    const reasonsList = [
        {
            icon: <CgArrowsExchange className='text-2xl' />,
            text: 'Smart Savings',
            backgroundImageUrl: 'url(images/smart-saving.png)',
        },
        {
            icon: <AiFillThunderbolt className='text-2xl' />,
            text: 'Instant Loans',
            backgroundImageUrl: 'url(images/instant-loans.png)',
        },
        {
            icon: <FaDollarSign className='text-2xl' />,
            text: 'Unlimited Stocks',
            backgroundImageUrl: 'url(images/unlimited-stocks.png)',
        },
    ];

    return (
        <Flex
            id='whySunga'
            py={{ base: '5dvh', lg: '10dvh' }}
            px={{ base: '6dvw', lg: '7dvw', xl: '8dvw' }}
            w='100%'
            h='100%'
            flexDir='column'
            gap={{ base: '48px', lg: '64px' }}
        >
            {/* title */}
            <Text
                textAlign='center'
                lineHeight={{ base: '32px', lg: '48px' }}
                fontSize={{ base: '32px', lg: '48px' }}
                fontWeight={700}
                color='black'
            >
                Why Sunga ?
            </Text>

            {/* content */}
            <Grid
                w='100%'
                gap='20px'
                templateColumns={{
                    base: '1fr',
                    lg: '1fr 1fr',
                    xl: '1fr 1fr 1fr',
                }}
            >
                {reasonsList.map((reason, index) => (
                    <GridItem
                        bg='purple.100'
                        backgroundImage={reason.backgroundImageUrl}
                        backgroundSize='cover'
                        backgroundPosition='center center'
                        backgroundRepeat='no-repeat'
                        p='20px'
                        h={{ base: '55dvh', lg: '60dvh' }}
                        rounded='20px'
                        w='100%'
                        display='flex'
                        flexDir='column'
                        justifyContent='space-between'
                        key={index}
                    >
                        <span className='bg-[#5846FB] rounded-[50%] h-10 w-10 text-white inline-flex items-center justify-center'>
                            {reason.icon}
                        </span>

                        <VStack w='100%' alignItems='flex-start'>
                            <Text
                                lineHeight={{ base: '32px', lg: '42px' }}
                                fontSize={{ base: '32px', lg: '42px' }}
                                color='black'
                                fontWeight={600}
                                w='53%'
                            >
                                {reason.text}
                            </Text>
                            <Text
                                lineHeight={{ base: '14px', lg: '14px' }}
                                fontSize={{ base: '14px', lg: '14px' }}
                                color='#5846FB'
                            >
                                Learn more
                            </Text>
                        </VStack>
                    </GridItem>
                ))}
            </Grid>
        </Flex>
    );
};

export default WhySunga;
