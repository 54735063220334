import {
    Button,
    Flex,
    HStack,
    Image,
    Link,
    Text,
    VStack,
} from '@chakra-ui/react';
import React from 'react';

const LearnAboutSunga = () => {
    return (
        <Flex
            id='investments'
            py={{ base: '3dvh', lg: '10dvh' }}
            px={{ base: '6dvw', lg: '7dvw', xl: '8dvw' }}
            justifyContent={{ base: 'flex-start', lg: 'space-between' }}
            w='100%'
            gap={{ base: '48px', lg: '20px' }}
            flexDir={{ base: 'column', lg: 'row' }}
        >
            {/* texts */}
            <VStack
                justifyContent={{ base: 'flex-start', lg: 'center' }}
                w={{ base: '100%', lg: '50%' }}
                alignItems='flex-start'
                gap={{ base: '38px', lg: '48px' }}
            >
                <Text color='black' fontSize={{ base: '20px', lg: '26px' }}>
                    Sunga has a mission to create a better, user-owned Smart
                    Saving. To do it we`re launching our app to the world. Join
                    Our waitlist.
                </Text>
                <Flex
                    flexDir={{ base: 'column', lg: 'row' }}
                    gap={{ base: '26px', lg: '20px' }}
                    w='100%'
                    alignItems={{ base: 'flex-start', lg: 'center' }}
                >
                    <Button
                        href='#footer'
                        as={Link}
                        w={{ base: '60%', lg: '40%' }}
                        _hover={{ opacity: '0.7', textDecor: 'none' }}
                        _active={{ opacity: '0.5' }}
                        bg='#5846FB'
                        h='54px'
                        color='white'
                        rounded='100px'
                    >
                        Join Waitlist
                    </Button>
                    <Link isExternal href='' fontSize='18px' color='#BBBBBB'>
                        Why Sunga ?
                    </Link>
                </Flex>
            </VStack>

            {/* image */}
            <HStack justifyContent='center' w={{ base: '100%', lg: '30%' }}>
                <Image
                    src='images/learn-more-icons.png'
                    // h={{ base: '65dvh', lg: '50dvh' }}
                    w={{ base: '100%', lg: '100%' }}
                    alt='learn more icon'
                />
            </HStack>
        </Flex>
    );
};

export default LearnAboutSunga;
