import {
    Avatar,
    Box,
    Button,
    Flex,
    Grid,
    HStack,
    Image,
    Text,
    VStack,
} from '@chakra-ui/react';
import React from 'react';
import { MdKeyboardArrowRight } from 'react-icons/md';
import BlogFooter from '../components/blog-footer';
import BlogHeader from '../components/blog-header';

const Blog = () => {
    // DATA INITIALIZATION
    const articleList = [
        {
            contentImg: '/images/sea-article.png',
            title: 'Technology',
            titleColor: '#4B6BFB',
            content:
                'The Impact of Technology on the Workplace: How Technology is Changing',
            authorName: 'Tracey Wilson',
            date: 'Aug 20, 2024',
            authorImg: '/images/blog-author-pic.png',
        },
        {
            contentImg: '/images/venice-article.png',
            title: 'Places',
            titleColor: '#4BFB72',
            content:
                'The Impact of Technology on the Workplace: How Technology is Changing',
            authorName: 'Tracey Wilson',
            date: 'Aug 20, 2024',
            authorImg: '/images/blog-author-pic.png',
        },
        {
            contentImg: '/images/car-article.png',
            title: 'Fashion',
            titleColor: '#FB4BBF',
            content:
                'The Impact of Technology on the Workplace: How Technology is Changing',
            authorName: 'Tracey Wilson',
            date: 'Aug 20, 2024',
            authorImg: '/images/blog-author-pic.png',
        },
        {
            contentImg: '/images/house-article.png',
            title: 'Nature',
            titleColor: '#AE4BFB',
            content:
                'The Impact of Technology on the Workplace: How Technology is Changing',
            authorName: 'Tracey Wilson',
            date: 'Aug 20, 2024',
            authorImg: '/images/blog-author-pic.png',
        },
    ];

    return (
        <Flex as='div' width='100dvw' flexDirection='column' align='stretch'>
            {/* header */}
            <BlogHeader />

            {/* vertical container */}
            <VStack
                mx='auto'
                spacing='42px'
                as='div'
                align='flex-start'
                w={{
                    base: '90%', // 0px
                    sm: '90%', // ~480px. em is a relative unit and is dependant on the font size.
                    md: '70dvw', // ~768px
                    lg: '85dvw', // ~992px
                    xl: '80dvw', // ~1280px
                    '2xl': '70dvw', // ~1536px
                }}
            >
                <Flex
                    flexDir='column'
                    w='100%'
                    gap={{ base: '3rem', lg: '4.5rem' }}
                    marginTop={{ base: '2em', lg: '4em' }}
                    marginBottom={{ base: '4em', lg: '4em' }}
                >
                    {/* blog */}
                    <VStack
                        w='100%'
                        alignItems='flex-start'
                        spacing={{ base: '16px', lg: '28px' }}
                    >
                        <Text
                            fontSize={{ base: '28px', lg: '42px' }}
                            fontWeight={700}
                            color='black'
                        >
                            Blog
                        </Text>

                        <Flex
                            gap='20px'
                            w='100%'
                            p={{ base: '12px', lg: '24px' }}
                            flexDir={{ base: 'column-reverse', lg: 'row' }}
                            rounded='26px'
                            shadow='0 10px 14px 4px rgba(0,0,0,0.275)'
                        >
                            <Flex
                                alignItems='flex-start'
                                gap='20px'
                                flexDir='column'
                                w={{ base: '100%', lg: '50%' }}
                            >
                                <Text
                                    fontWeight={700}
                                    fontSize={{ base: '18px', lg: '38px' }}
                                    lineHeight={{ base: '18px', lg: '52px' }}
                                >{`This is feature article\n-the most important piece of content`}</Text>
                                <Text
                                    fontSize={{ base: '16px', lg: '26px' }}
                                    color='black'
                                    fontWeight={300}
                                >
                                    {`Short description of what's actually being discussed in this article, maybe the first sentence to be previewed`}
                                </Text>
                                <Button
                                    rightIcon={<MdKeyboardArrowRight />}
                                    rounded='20px'
                                    bg='black'
                                    color='white'
                                >
                                    Read More
                                </Button>
                            </Flex>

                            {/* images */}
                            <Box w={{ base: '100%', lg: '50%' }}>
                                <Image
                                    w='100%'
                                    h='100%'
                                    alt='fox image'
                                    src='/images/blog-fox.png'
                                />
                            </Box>
                        </Flex>
                    </VStack>

                    {/* All articles */}
                    {/* blog */}
                    <VStack
                        w='100%'
                        alignItems='flex-start'
                        spacing={{ base: '16px', lg: '28px' }}
                    >
                        <Text
                            fontSize={{ base: '28px', lg: '42px' }}
                            fontWeight={700}
                            color='black'
                        >
                            All Articles
                        </Text>

                        <Grid
                            w='100%'
                            templateColumns={{
                                base: '1fr',
                                md: '1fr 1fr',
                                lg: '1fr 1fr 1fr',
                                // xl: '1fr 1fr 1fr 1fr',
                            }}
                            gap='26px'
                        >
                            {articleList.map((article, index) => (
                                <Flex
                                    key={index}
                                    h={{
                                        base: '50dvh',
                                        lg: '55dvh',
                                        xl: '60dvh',
                                    }}
                                    pos='relative'
                                    boxShadow='xl'
                                    rounded='20px'
                                    flexDir='column'
                                >
                                    <Image
                                        zIndex={4}
                                        pos='absolute'
                                        top='0'
                                        left='0'
                                        w='100%'
                                        h='60%'
                                        objectFit='cover'
                                        roundedTop='24px'
                                        alt='article image'
                                        src={article.contentImg}
                                    />

                                    <Flex
                                        mt='auto'
                                        bg='white'
                                        pos='relative'
                                        zIndex={5}
                                        rounded='24px'
                                        p={{ base: '14px', lg: '14px' }}
                                        gap='10px'
                                        flexDir='column'
                                    >
                                        <Text
                                            color={article.titleColor}
                                            fontSize={{
                                                base: '12px',
                                                lg: '14px',
                                            }}
                                        >
                                            {article.title}
                                        </Text>
                                        <Text
                                            fontWeight={600}
                                            color='black'
                                            fontSize={{
                                                base: '16px',
                                                lg: '20px',
                                            }}
                                        >
                                            {article.content}
                                        </Text>
                                        <HStack
                                            justifyContent='space-between'
                                            alignItems='center'
                                        >
                                            <Avatar
                                                size='sm'
                                                src={article.authorImg}
                                            />
                                            <Text
                                                color='gray.400'
                                                fontWeight={600}
                                                fontSize={{
                                                    base: '14px',
                                                    lg: '18px',
                                                }}
                                            >
                                                {article.authorName}
                                            </Text>
                                            <Text
                                                color='gray.400'
                                                fontWeight={600}
                                                fontSize={{
                                                    base: '14px',
                                                    lg: '18px',
                                                }}
                                            >
                                                {article.date}
                                            </Text>
                                        </HStack>
                                    </Flex>
                                </Flex>
                            ))}
                        </Grid>
                    </VStack>
                </Flex>
            </VStack>

            {/* footer */}
            <BlogFooter />
        </Flex>
    );
};

export default Blog;
