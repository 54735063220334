import { ChakraProvider } from '@chakra-ui/react';
import React from 'react';
import { Provider } from 'react-redux';
import { ToastContainer, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { store } from './redux/store';
import AppRouter from './routing/router';

function App() {
    return (
        <>
            <ChakraProvider>
                <Provider store={store}>
                    <AppRouter />
                </Provider>
            </ChakraProvider>

            {/* toast container */}
            <ToastContainer
                position='top-right'
                autoClose={3000}
                newestOnTop
                limit={1}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss={false}
                draggable={false}
                transition={Zoom}
                pauseOnHover
                draggablePercent={40}
                theme='colored'
            />
        </>
    );
}

export default App;
