import React from 'react';

const LogoImage = ({
    width = 'w-[46.401px]',
    url = '/images/logo-main.svg',
}) => {
    return (
        <div className='flex items-center'>
            <img src={url} alt='logo' className={`${width}`} />
        </div>
    );
};

export default LogoImage;
