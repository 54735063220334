import './index.css';

const HamburgerMenu = ({ isMenuOpen, onClick }) => {
    return (
        <div className='menu-toggle hidden mobile:flex'>
            <input checked={isMenuOpen} onClick={onClick} type='checkbox' />

            <span></span>
            <span></span>
            <span></span>
        </div>
    );
};

export default HamburgerMenu;
