import { Flex, Image, Text, VStack } from '@chakra-ui/react';
import React from 'react';

const DefineGoals = () => {
    return (
        <Flex
            id='defineGoals'
            py={{ base: '5dvh', lg: '10dvh' }}
            px={{ base: '6dvw', lg: '7dvw', xl: '8dvw' }}
            flexDir={{ base: 'column', lg: 'row' }}
            w='100%'
            alignItems={{ base: 'flex-start', lg: 'center' }}
            justifyContent={{ base: 'center', lg: 'space-between' }}
            gap='28px'
        >
            {/* texts */}
            <VStack
                gap={{ base: '20px', lg: '36px' }}
                alignItems='flex-start'
                w={{ base: '100%', lg: '40%' }}
            >
                <Text
                    w={{ base: '60%', lg: '70%' }}
                    lineHeight={{ base: '32px', lg: '56px' }}
                    fontSize={{ base: '32px', lg: '56px' }}
                    fontWeight={700}
                    color='black'
                >
                    Define Your Goals 💰
                </Text>
                <Text fontSize='16px' color='black' fontWeight={400}>
                    Dream big! Set savings goals for that dream vacation, a new
                    gadget, or your ideal home. Sunga is here to help you
                    achieve them.
                </Text>
            </VStack>

            {/* image */}
            <Image
                src='/images/goals-ui.png'
                alt='ui for a mobile device'
                w={{ base: '100%', lg: '40%' }}
            />
        </Flex>
    );
};

export default DefineGoals;
