import {
    Button,
    Flex,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalOverlay,
    Text,
    VStack,
    useDisclosure,
} from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import React from 'react';
import { FaArrowLeft, FaRegUser } from 'react-icons/fa';
import { FaRegCircleCheck } from 'react-icons/fa6';
import { HiOutlineMail } from 'react-icons/hi';
import { IoLocationOutline } from 'react-icons/io5';
import { MdOutlineLocalPhone } from 'react-icons/md';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import FormError from '../components/shared/form-error';
import Input from '../components/shared/input';
import LogoImage from '../components/shared/logo-image';
import SelectInput from '../components/shared/select-input';
import WaitlistWrapperLayout from '../layouts/waitlist-wrapper';
import { useAddUserToWaitlistMutation } from '../redux/services/sungaApi';
import { PHONE_REGEX, selectOptions } from '../utils/globals';
const { ageBracket, sex } = selectOptions;

const WaitList = () => {
    // HOOKS
    const {
        isOpen: isOpenModal,
        onOpen: onOpenModal,
        onClose: onCloseModal,
    } = useDisclosure();
    const navigate = useNavigate();
    const [addUserToWaitlist, { isLoading, isSuccess }] =
        useAddUserToWaitlistMutation();

    return (
        <>
            <Formik
                initialValues={{
                    fullName: '',
                    email: '',
                    phone: '',
                    age: '',
                    location: '',
                    sex: '',
                }}
                validateOnChange
                validationSchema={Yup.object().shape({
                    fullName: Yup.string().required('Required'),
                    email: Yup.string()
                        .email('Invalid email address')
                        .required('Required'),
                    phone: Yup.string()
                        .matches(PHONE_REGEX, `Phone number isn't valid`)
                        .required('Required'),
                    age: Yup.string().required('Required'),
                    location: Yup.string().required('Required'),
                    sex: Yup.string().required('Required'),
                })}
                onSubmit={(values, { resetForm }) => {
                    addUserToWaitlist(values)
                        .unwrap()
                        .then(() => {
                            resetForm();
                            onOpenModal();
                        })
                        .catch((error) => {
                            console.log(error);
                            toast.error(
                                error?.message ||
                                    `Couldn't add you to the waitlist`,
                                {
                                    toastId: 'addToWaitlistError',
                                }
                            );
                        });
                }}
            >
                {({
                    handleBlur,
                    handleChange,
                    setFieldValue,
                    values,
                    isValid,
                    setFieldTouched,
                }) => {
                    return (
                        <WaitlistWrapperLayout>
                            {/* wrapper container */}
                            <div className='no-scrollbar w-full flex flex-col items-center mobile:gap-10 gap-20 mobile:px-1'>
                                {/* logo container */}
                                <div className='w-full flex flex-col gap-10 mobile:gap-8 items-center'>
                                    {/* logo */}
                                    <div className='w-[60%] mobile:w-full flex items-center justify-between'>
                                        {/* back button */}
                                        <button
                                            onClick={() => navigate(-1)}
                                            className='text-base text-black font-semibold inline-flex items-center gap-2'
                                        >
                                            <FaArrowLeft className='text-base text-black' />
                                            Back
                                        </button>

                                        <Link
                                            className='hover:opacity-80'
                                            to='/'
                                        >
                                            <LogoImage
                                                url='/images/logo-black.svg'
                                                width='mobile:h-8 mobile:w-auto w-32'
                                            />
                                        </Link>

                                        <span className='w-[4dvw] mobile:w-[17dvw] md:!w-[8dvw]' />
                                    </div>

                                    {/* texts */}
                                    <div className='w-full flex flex-col items-center gap-1 text-center'>
                                        {/* first text */}
                                        <h3 className='font-extrabold text-[#5846FB] text-base mobile:text-xs'>
                                            Get started
                                        </h3>

                                        {/* second text */}
                                        <h4 className='font-bold text-5xl mobile:text-3xl w-1/2 mobile:w-full text-[#5846FB]'>
                                            Join the <br />
                                            waitlist for $unga
                                        </h4>
                                    </div>
                                </div>

                                {/* form */}
                                <Form className='flex flex-col gap-10 w-[60%] mobile:w-full'>
                                    {/* container */}
                                    <div className='flex flex-col w-full gap-7'>
                                        {/* name*/}
                                        <div className='flex flex-col gap-2'>
                                            <label className='text-[#191923] text-lg font-medium mobile:text-sm'>
                                                Name:
                                            </label>

                                            <Input
                                                name='fullName'
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.fullName}
                                                placeholderText='Enter name'
                                                Icon={FaRegUser}
                                            />
                                            <FormError name='fullName' />
                                        </div>

                                        {/* phone number*/}
                                        <div className='flex flex-col gap-2'>
                                            <label className='text-[#191923] text-lg font-medium mobile:text-sm'>
                                                Phone number:
                                            </label>

                                            <Input
                                                name='phone'
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.phone}
                                                placeholderText='Enter phone'
                                                Icon={MdOutlineLocalPhone}
                                            />
                                            <FormError name='phone' />
                                        </div>

                                        {/* email*/}
                                        <div className='flex flex-col gap-2'>
                                            <label className='text-[#191923] text-lg font-medium mobile:text-sm'>
                                                Email:
                                            </label>

                                            <Input
                                                name='email'
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.email}
                                                placeholderText='Enter email'
                                                Icon={HiOutlineMail}
                                            />
                                            <FormError name='email' />
                                        </div>

                                        {/* location*/}
                                        <div className='flex flex-col gap-2'>
                                            <label className='text-[#191923] text-lg font-medium mobile:text-sm'>
                                                Location:
                                            </label>

                                            <Input
                                                name='location'
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.location}
                                                placeholderText='Enter location'
                                                Icon={IoLocationOutline}
                                            />
                                            <FormError name='location' />
                                        </div>

                                        {/* sex */}
                                        <div className='flex flex-col gap-2'>
                                            <label className='text-[#191923] text-lg font-medium mobile:text-sm'>
                                                Sex:
                                            </label>

                                            <SelectInput
                                                name='sex'
                                                options={sex}
                                                placeholder='Select sex'
                                                value={
                                                    isSuccess
                                                        ? null
                                                        : sex.find(
                                                              (sex) =>
                                                                  sex.value ===
                                                                  values.sex
                                                          )
                                                }
                                                onChange={(selectedOption) => {
                                                    setFieldValue(
                                                        'sex',
                                                        selectedOption?.value ||
                                                            ''
                                                    ).then(() => {
                                                        setFieldTouched('sex');
                                                    });
                                                }}
                                                onBlur={handleBlur}
                                            />
                                            <FormError name='sex' />
                                        </div>

                                        {/* Age bracket */}
                                        <div className='flex flex-col gap-2'>
                                            <label className='text-[#191923] text-lg font-medium mobile:text-sm'>
                                                Age bracket:
                                            </label>

                                            <SelectInput
                                                name='age'
                                                options={ageBracket}
                                                placeholder='Select age bracket'
                                                value={
                                                    isSuccess
                                                        ? null
                                                        : ageBracket.find(
                                                              (age) =>
                                                                  age.value ===
                                                                  values.age
                                                          )
                                                }
                                                onChange={(selectedOption) => {
                                                    setFieldValue(
                                                        'age',
                                                        selectedOption?.value ||
                                                            ''
                                                    ).then(() => {
                                                        setFieldTouched('age');
                                                    });
                                                }}
                                                onBlur={handleBlur}
                                            />
                                            <FormError name='age' />
                                        </div>
                                    </div>

                                    {/* Buttons */}
                                    <VStack gap='32px' w='100%' spacing={3}>
                                        <Button
                                            isLoading={isLoading}
                                            isDisabled={!isValid || isLoading}
                                            type='submit'
                                            _hover={{ opacity: '0.7' }}
                                            _active={{ opacity: '0.5' }}
                                            bg='#5846FB'
                                            h='54px'
                                            color='white'
                                            rounded='10px'
                                            w='100%'
                                        >
                                            Join the waitlist
                                        </Button>
                                    </VStack>
                                </Form>
                            </div>
                        </WaitlistWrapperLayout>
                    );
                }}
            </Formik>

            {/* modal */}
            <Modal onClose={onCloseModal} size='md' isOpen={isOpenModal}>
                <ModalOverlay />
                <ModalContent m='auto'>
                    <ModalBody p={{ base: '20px', lg: '32px' }}>
                        <Flex
                            flexDir='column'
                            w='100%'
                            alignItems='center'
                            gap={{ base: '16px', lg: '26px' }}
                        >
                            <Text
                                textAlign='center'
                                fontSize={{ base: '20px', lg: '26px' }}
                                fontWeight={500}
                            >{`You've been added to the waitlist!`}</Text>
                            <FaRegCircleCheck className='text-[#5846FB] mobile:text-[54px] text-[72px]' />
                        </Flex>
                    </ModalBody>
                    <ModalFooter w='100%'>
                        {/* Button */}
                        <Button
                            onClick={onCloseModal}
                            _hover={{ opacity: '0.7' }}
                            _active={{ opacity: '0.5' }}
                            bg='#5846FB'
                            h='54px'
                            color='white'
                            rounded='10px'
                            w='100%'
                        >
                            Close
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};

export default WaitList;
