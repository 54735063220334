import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import apiEndpoints from './endpoints.json';

export const sungaApi = createApi({
    reducerPath: 'sungaApi',
    // refetchOnFocus: true,
    refetchOnReconnect: true,
    baseQuery: fetchBaseQuery({
        baseUrl: apiEndpoints.prodUrl,
        timeout: 1500 * 60,
    }),
    endpoints: (builder) => ({
        addUserToWaitlist: builder.mutation({
            query: (body) => ({
                url: apiEndpoints.waitlist.addUser,
                method: 'POST',
                body,
            }),
        }),
    }),
});

export const { useAddUserToWaitlistMutation } = sungaApi;
