import { Suspense, lazy } from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import LoadingPageLayout from '../layouts/loading-page';
import Blog from '../pages/Blog';
import HomePage from '../pages/HomePage';
import WaitList from '../pages/WaitList';
const PageNotFound = lazy(() => import('../pages/PageNotFound'));

const AppRouter = () => {
    return (
        <Router>
            <Suspense fallback={<LoadingPageLayout />}>
                <Routes>
                    {/* NOT FOUND/ INVALID ROUTES */}
                    <Route path='*' element={<PageNotFound />} />

                    {/* HOME */}
                    <Route path='/' element={<HomePage />} />

                    {/* WAITLIST PAGE */}
                    <Route path='/waitlist' element={<WaitList />} />

                    {/* BLOG */}
                    <Route path='/blog' element={<Blog />} />
                </Routes>
            </Suspense>
        </Router>
    );
};

export default AppRouter;
