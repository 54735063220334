import {
    Button,
    Flex,
    HStack,
    Image,
    Link,
    Text,
    VStack,
} from '@chakra-ui/react';
import React from 'react';
import { FaDollarSign } from 'react-icons/fa6';

const AboutSunga = () => {
    // DATA INITIALIZATION
    const sungaFeaturesList = [
        {
            text: 'Maximize your Savings Potential by saving in Dollars',
            iconColor: 'text-[#FFC700]',
        },
        {
            text: 'Get Instant Loan Access',
            iconColor: 'text-[#B36AE2]',
        },
        {
            text: 'Buy Unlimited US Stock',
            iconColor: 'text-[#51A7F9]',
        },
        {
            text: 'Personalized Mobile App.',
            iconColor: 'text-[#FFFFFF]',
        },
    ];

    return (
        <Flex
            rounded={{ base: '14px', lg: '42px' }}
            id='savingsGoals'
            py={{ base: '5dvh', lg: '10dvh' }}
            px={{ base: '6dvw', lg: '7dvw', xl: '8dvw' }}
            w='100%'
            flexDir='column'
            bg='black'
            gap={{ base: '48px', lg: '72px' }}
        >
            {/* launching soon info */}
            <Flex
                justifyContent={{ base: 'flex-start', lg: 'space-between' }}
                w='100%'
                gap={{ base: '48px', lg: '20px' }}
                flexDir={{ base: 'column', lg: 'row' }}
            >
                <VStack
                    justifyContent={{ base: 'flex-start', lg: 'space-between' }}
                    w={{ base: '100%', lg: '50%' }}
                    alignItems='flex-start'
                >
                    <VStack
                        gap={{ base: '12px', lg: '20px' }}
                        w='100%'
                        alignItems='flex-start'
                    >
                        <Text
                            color='#5846FB'
                            fontSize={{ base: '16px', lg: '18px' }}
                        >
                            LAUNCHING SOON
                        </Text>
                        <Text
                            display={{ base: 'none', lg: 'block' }}
                            color='white'
                            fontSize={{ base: '32px', lg: '48px' }}
                            fontWeight={500}
                        >
                            Sunga App!
                        </Text>
                        <Text
                            display={{ base: 'block', lg: 'none' }}
                            color='white'
                            fontSize={{ base: '48px', lg: '48px' }}
                            fontWeight={500}
                        >
                            Smart Saving App. Sunga !
                        </Text>
                    </VStack>
                    <VStack
                        gap={{ base: '26px', lg: '20px' }}
                        w={{ base: '100%', lg: '60%' }}
                        alignItems='flex-start'
                    >
                        <Text
                            color='white'
                            fontSize={{ base: '14px', lg: '14px' }}
                        >
                            Sunga has a mission to create a better, user-owned
                            Smart Saving .To do it we`re launching our app to
                            the world. Join Our waitlist.
                        </Text>
                        <Button
                            href='#footer'
                            as={Link}
                            _hover={{ opacity: '0.7', textDecor: 'none' }}
                            w={{ base: '60%', lg: '40%' }}
                            _active={{ opacity: '0.5' }}
                            bg='#5846FB'
                            h='54px'
                            color='white'
                            rounded='100px'
                        >
                            Join Waitlist
                        </Button>
                    </VStack>
                </VStack>
                <HStack justifyContent='center' w={{ base: '100%', lg: '30%' }}>
                    <Image
                        src='images/secure-safe.png'
                        h={{ base: '65dvh', lg: '50dvh' }}
                        alt='secure and safe icon'
                    />
                </HStack>
            </Flex>

            {/* everything sunga */}
            <VStack gap='28px' w='100%' alignItems='flex-start'>
                <Text
                    fontWeight={500}
                    fontSize={{ base: '28px', lg: '48px' }}
                    color='white'
                >
                    What more could you ask for than Sunga
                </Text>
                <Flex
                    flexDir={{ base: 'column', lg: 'row' }}
                    justifyContent='space-between'
                    alignItems='stretch'
                    w='100%'
                    gap={{ base: '20px', lg: '0px' }}
                >
                    {sungaFeaturesList.map((item, index) => (
                        <Flex
                            key={index}
                            alignItems='center'
                            justifyContent='flex-start'
                            gap='20px'
                            w={{ base: '100%', lg: '23%' }}
                            rounded='100px'
                            py='20px'
                            px='32px'
                            bg='#1F2123'
                        >
                            <FaDollarSign
                                className={`${item.iconColor} text-[54px] w-[30%] mobile:minW-[20%] font-bold`}
                            />
                            <Text
                                // w='60%'
                                fontWeight={400}
                                color='white'
                                fontSize={{ base: '14px', lg: '18px' }}
                            >
                                {item.text}
                            </Text>
                        </Flex>
                    ))}
                </Flex>
            </VStack>
        </Flex>
    );
};

export default AboutSunga;
