import { Box } from '@chakra-ui/react';
import React from 'react';
import Footer from '../components/footer';

import AboutSunga from '../components/about-sunga';
import DefineGoals from '../components/define-your-goals';
import Jumbotron from '../components/jumbotron';
import LearnAboutSunga from '../components/learn-about-sunga';
import NavBar from '../components/shared/nav-bar';
import WhySunga from '../components/why-sunga';

const HomePage = () => {
    return (
        <div className='w-full min-h-full gap-20 flex flex-col relative overflow-hidden mobile:gap-12'>
            <Box
                w='100%'
                px={{ base: '6dvw', lg: '7dvw', xl: '8dvw' }}
                bg='black'
                roundedBottom={{ base: '20px', lg: '50px' }}
                h='100%'
            >
                {/* navbar */}
                <NavBar />
                {/* hero section */}
                <Jumbotron />
            </Box>
            {/* key features */}
            <DefineGoals />

            {/* how it works */}
            <WhySunga />

            {/* savings goals showcase */}
            <AboutSunga />

            {/* investment opportunites */}
            <LearnAboutSunga />

            {/* Footer */}
            <Footer />
        </div>
    );
};
{
    /* <WrapperLayout>  </WrapperLayout> */
}

export default HomePage;
