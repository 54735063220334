import { Button, Flex, Link, Text, VStack } from '@chakra-ui/react';
import React from 'react';
import { FaDollarSign } from 'react-icons/fa6';

const Jumbotron = () => {
    // DATA INITIALIZATION
    const sungaFeaturesList = [
        {
            text: 'Maximize your Savings Potential by saving in Dollars',
            iconColor: 'text-[#FFC700]',
        },
        {
            text: 'Get Instant Loan Access',
            iconColor: 'text-[#B36AE2]',
        },
        {
            text: 'Buy Unlimited US Stock',
            iconColor: 'text-[#51A7F9]',
        },
        {
            text: 'Personalized Mobile App.',
            iconColor: 'text-[#FFFFFF]',
        },
    ];

    return (
        <Flex
            pos='relative'
            zIndex={100}
            bg='transparent'
            alignItems='center'
            flexDir='column'
            pb={{ base: '6dvh', lg: '10dvh' }}
            gap={{ base: '36px', lg: '28px' }}
            pt={{ base: '16dvh', lg: '15dvh' }}
        >
            {/* jumbotron */}
            <VStack gap='24px' w='100%'>
                <Text
                    textAlign='center'
                    w={{ base: '70%', lg: '100%' }}
                    fontSize={{ base: '42px', lg: '86px' }}
                    color='white'
                    fontWeight={500}
                >
                    Welcome to Sunga
                </Text>
                <Text
                    textAlign='center'
                    w={{ base: '70%', lg: '100%' }}
                    fontSize={{ base: '16px', lg: '24px' }}
                    color='white'
                    fontWeight={400}
                >
                    Empower your finance, Expand your Horizon
                </Text>
                <VStack mt='28px' gap='32px' w='100%' spacing={3}>
                    <Button
                        as={Link}
                        href='/waitlist'
                        _hover={{ opacity: '0.7', textDecor: 'none' }}
                        _active={{ opacity: '0.5' }}
                        bg='#5846FB'
                        h='54px'
                        color='white'
                        rounded='100px'
                        w={{ base: '55%', lg: '20%' }}
                    >
                        Join Waitlist
                    </Button>
                </VStack>
            </VStack>

            {/* everything sunga */}
            <VStack gap='28px' w='100%' alignItems='flex-start'>
                <Text
                    fontWeight={500}
                    fontSize={{ base: '18px', lg: '22px' }}
                    color='white'
                >
                    Everything Sunga
                </Text>
                <Flex
                    flexDir={{ base: 'column', lg: 'row' }}
                    justifyContent='space-between'
                    alignItems='stretch'
                    w='100%'
                    gap={{ base: '20px', lg: '0px' }}
                >
                    {sungaFeaturesList.map((item, index) => (
                        <Flex
                            key={index}
                            alignItems='center'
                            justifyContent='flex-start'
                            gap='20px'
                            w={{ base: '100%', lg: '23%' }}
                            rounded='100px'
                            py='20px'
                            px='32px'
                            bg='#1F2123'
                        >
                            <FaDollarSign
                                className={`${item.iconColor} text-[54px] w-[30%] mobile:minW-[20%] font-bold`}
                            />
                            <Text
                                // w='60%'
                                fontWeight={400}
                                color='white'
                                fontSize={{ base: '14px', lg: '18px' }}
                            >
                                {item.text}
                            </Text>
                        </Flex>
                    ))}
                </Flex>
            </VStack>
        </Flex>
    );
};

export default Jumbotron;
