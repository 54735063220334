import React from 'react';

const Input = ({
    Icon,
    id,
    placeholderText = '',
    disableIcon = false,
    name = '',
    type = 'text',
    onChange,
    value,
    onFocus,
    onBlur,
    disabled,
}) => {
    return (
        <div className='flex items-center w-full border-[1.5px] border-[#191923] rounded-lg bg-white h-11 px-3 gap-x-3'>
            {/* icon */}
            {disableIcon || !Icon ? (
                <></>
            ) : (
                <Icon className='text-lg text-[#586E9B]' />
            )}

            {/* input */}
            <input
                id={id}
                placeholder={placeholderText}
                name={name}
                value={value}
                type={type}
                onChange={onChange}
                onFocus={onFocus}
                onBlur={onBlur}
                disabled={disabled}
                className='h-full w-full border-none [outline:none] text-base text-[#191923] font-normal placeholder:text-[#6A6C70] placeholder:font-normal placeholder:text-base'
            />
        </div>
    );
};

export default Input;
