import { Button, Flex, HStack, Link, List, ListItem } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import HamburgerMenu from '../shared/hamburger-menu';
import LogoImage from '../shared/logo-image';

const BlogHeader = () => {
    // DATA INITIALIZATION
    const navListItem = [
        {
            title: 'Home',
            route: '/blog',
        },
        {
            title: 'Resources',
            route: '/',
        },
        {
            title: 'Contact',
            route: '/',
        },
    ];
    const location = useLocation();

    // STATES
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [show, setShow] = useState(false);
    const [lastScrollY, setLastScrollY] = useState(0);

    // HANDLERS
    const toggleMenu = () => setIsMenuOpen(!isMenuOpen);
    const controlNavbar = () => {
        if (typeof window !== 'undefined') {
            if (window.scrollY < 30) {
                setShow(false);
            } else if (window.scrollY > lastScrollY) {
                // if scroll down hide the navbar
                setShow(false);
            } else {
                // if scroll up show the navbar
                setShow(true);
            }

            // remember current page location to use in the next move
            setLastScrollY(window.scrollY);
        }
    };

    // SIDE EFFECTS
    useEffect(() => {
        if (isMenuOpen) {
            document.body.style.overflowY = 'hidden';
        }
        return () => {
            document.body.style.overflowY = 'scroll';
        };
    }, [isMenuOpen]);
    useEffect(() => {
        if (typeof window !== 'undefined') {
            window.addEventListener('scroll', controlNavbar);

            // cleanup function
            return () => {
                window.removeEventListener('scroll', controlNavbar);
            };
        }
    }, [lastScrollY]);

    return (
        <Flex h='10dvh' w='100%' bg='#4532EE'>
            <HStack
                justifyContent='space-between'
                alignItems='center'
                mx='auto'
                w={{
                    base: '90%', // 0px
                    sm: '90%', // ~480px. em is a relative unit and is dependant on the font size.
                    md: '70dvw', // ~768px
                    lg: '85dvw', // ~992px
                    xl: '80dvw', // ~1280px
                    '2xl': '70dvw', // ~1536px
                }}
            >
                {/* logo */}
                <Link _hover={{ opacity: 0.8 }} href='/'>
                    <LogoImage
                        url='/images/main-logo.svg'
                        width='mobile:h-8 pc:w-28'
                    />
                </Link>

                {/* hamburger menu */}
                <HamburgerMenu isMenuOpen={isMenuOpen} onClick={toggleMenu} />

                {/* desktop menu */}
                <List
                    display={{ base: 'none', lg: 'flex' }}
                    alignItems='center'
                    textAlign='center'
                    gap='24px'
                    fontSize='18px'
                    fontWeight={600}
                >
                    {navListItem.map((navItem, index) => (
                        <ListItem
                            mx='auto'
                            as={Link}
                            href={navItem.route}
                            key={index}
                            py='8px'
                            color={
                                location.pathname === navItem.route
                                    ? '#F89500'
                                    : 'white'
                            }
                        >
                            {navItem.title}
                        </ListItem>
                    ))}

                    {/* join waitlist */}
                    <Button
                        ml='16px'
                        as={Link}
                        href='/waitlist'
                        _hover={{
                            opacity: '0.7',
                            color: '#4532EE',
                            textDecor: 'none',
                        }}
                        _active={{ opacity: '0.5' }}
                        bg='white'
                        w='10dvw'
                        h='40px'
                        color='#4532EE'
                        rounded='12px'
                    >
                        Join Us
                    </Button>
                </List>
            </HStack>

            {/* mobile menu */}
            <List
                display='flex'
                flexDir='column'
                alignItems='stretch'
                bg='white'
                px='16px'
                py='24px'
                textAlign='center'
                gap='16px'
                fontSize='18px'
                fontWeight={600}
                w='100%'
                h='90dvh'
                className={`[transition:transform_.7s_ease-in-out] fixed h-[90dvh] left-0 top-[10.1dvh] z-[2000] ${
                    isMenuOpen ? 'translate-x-0' : 'translate-x-[100dvw]'
                } pc:hidden`}
            >
                {navListItem.map((navItem, index) => (
                    <ListItem
                        mx='auto'
                        as={Link}
                        href={navItem.route}
                        key={index}
                        rounded='4px'
                        w='fit-content'
                        color={
                            location.pathname === navItem.route
                                ? '#F89500'
                                : 'black'
                        }
                        _hover={{ opacity: 0.8, textDecor: 'none' }}
                    >
                        {navItem.title}
                    </ListItem>
                ))}

                {/* join waitlist */}
                <Button
                    mt='18px'
                    as={Link}
                    href='/waitlist'
                    _hover={{
                        opacity: '0.7',
                        color: 'white',
                        textDecor: 'none',
                    }}
                    _active={{ opacity: '0.5' }}
                    bg='#5846FB'
                    h='54px'
                    color='white'
                    rounded='12px'
                >
                    Join Us
                </Button>
            </List>
        </Flex>
    );
};

export default BlogHeader;
