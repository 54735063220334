import React from 'react';
import LogoImage from '../../components/shared/logo-image';

const LoadingPageLayout = () => {
    return (
        <div className='w-[100dvw] h-[100dvh] bg-primary flex items-center justify-center'>
            <LogoImage width='75px' />
        </div>
    );
};

export default LoadingPageLayout;
