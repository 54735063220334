// SELECT OPTIONS
export const selectOptions = {
    sex: [
        {
            label: 'Male',
            value: 'Male',
        },
        {
            label: 'Female',
            value: 'Female',
        },
    ],
    ageBracket: [
        {
            label: '15 - 17',
            value: '15 - 17',
        },
        {
            label: '18 - 25',
            value: '18 - 25',
        },
        {
            label: '26 - 30',
            value: '26 - 30',
        },
        {
            label: '30 - 35',
            value: '30 - 35',
        },
        {
            label: '36 - 40',
            value: '36 - 40',
        },
    ],
};

// REGEX
export const PHONE_REGEX = new RegExp(
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
);
